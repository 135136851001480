import React from "react"
import { Footer, Header, Page } from '@components';
import SEO from "../components/seo"
import NotFound from '@sections/404/NotFound'

const NotFoundPage = () => (
  <Page>
    <SEO title="404: Not found" />
    <Header />
    <NotFound />
    <Footer />
  </Page>
)

export default NotFoundPage
