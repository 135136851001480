import React from 'react';
import { Container } from '@components';
import Image from '@images/404.svg';
import './styles.scss';

const NotFound = () => (
  <div className="NotFound">
    <Container>
      <div className="NotFound-wrapper">
        <div className="NotFound-title">
          <h1>
            <span>Whoops!</span>
            <br />
            <span>Page not found...</span>
          </h1>
          <div className="NotFound-image-mobile">
            <img
              alt="Not found"
              src={Image}
              width="100%"
            />
          </div>
          <a href="/" className="btn">
            Back to Home
          </a>
        </div>
        <div className="NotFound-image">
          <img
            alt="Not found"
            src={Image}
            width="100%"
          />
        </div>
      </div>
    </Container>
  </div>
);

export default NotFound;
